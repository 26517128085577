<template>
    <div class="body">
        <div class="head">
            <div class="icon"><img src="../../assets/images/official/toolbox.png"></div>
            <div class="name">工具箱列表</div>
            <div class="contact"><a @click="toUrl('/about#contact')">联系我们获得更多信息 &gt; &gt;</a> </div>
            <div class="clear"></div>
        </div>
        <ul>
            <li v-for="(item, index) in data.items" :key="index">
                <div class="image">
                    <img :src="item.cover" />
                </div>
                <div class="line">
                    <img src="../../assets/images/official/line.jpg" />
                </div>
                <div class="text">
                    <div class="title">{{ item.title }}</div>
                    <div class="description" v-html="item.description"></div>
                </div>
                <div class="clear"></div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  created() {
    console.log(this.data);
  },
  methods: {
    handleClick() {
      window.open('https://mohub.net', '_blank');
    },
    toUrl(url) {
      if (url.includes('http')) {
        window.open(url);
      } else {
        this.$router.push(url);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.clear {
    clear: both;
}

.body {
    width: 100%;
    max-width: 1200px;
    margin: 90px auto;
}

.head {
    padding-bottom: 14px;
    border-bottom: 2px solid #3e3a39;

    .icon {
        width: 52px;
        float: left;
    }

    .icon img {
        width: 100%;
    }

    .name {
        float: left;
        font-size: 24px;
        padding-top: 12px;
        margin-left: 30px;
    }

    .contact {
        float: right;
        font-size: 24px;
        padding-top: 12px;
        margin-left: 30px;
        color: #3c7bbf;
    }

    .contact a {
        cursor: pointer;
        color: #3c7bbf;
    }
}

.body ul {
    margin-left: -3%;
}

.body li {
    height: 350px;
    padding-left: 1.8%;
    border-bottom: 1px solid #acacac;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 27.5%;
    height: 100%;
    float: left;
}

.image img {
    width: 100%;
    max-height: 90%;
    display: block;
    margin: auto;
}

.line {
    height: 240px;
    width: 4.5%;
    float: left;
    text-align: center;
}

.line img {
    height: 100%;
}

.text {
    width: 68%;
    float: right;

    .title {
        color: #585858;
        font-size: 30px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }

    .description {
        color: #676767;
        font-size: 18px;
        line-height: 36px;
        margin: 40px 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        /deep/ a{
            color: #3c7bbf;
        }
    }
}

@media screen and (max-width: 700px) {
    .body ul {
        width: 96%;
        margin: 0 auto;
    }

    .body li {
        height: auto;
        padding: 5vw 0;
        display: block;
    }

    .image {
        width: 80%;
        margin: 0 auto;
        float: none;
    }

    .image img {
        max-height: 60vw;
    }

    .text {
        float: none;
        width: 100%;

        .title {
            font-size: 5vw;
            margin-top: 2vw;
        }

        .description {
            display: block;
            margin: 2vw 0;
            font-size: 3.8vw;
            line-height: 5vw;
        }
    }

    .line {
        display: none;
    }
}
</style>
